(function() {

	var nav_link = $('.navigation__item a'),
		nav_item = $('.navigation__item');

	$(window).scroll(function() {
		var window_top = $(window).scrollTop();
	});

	//jquery.scrollTo.js is needed//

	nav_link.click(function(env) {
		env.preventDefault();
		$('html, body').scrollTo(this.hash, this.hash);
	});

	nav_item.click(function() {
		$(this).siblings().removeClass('active');
		$(this).addClass('active');
	});

})();