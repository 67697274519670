(function() {
	$(document).ready(function() {
		var callForm = $('.call-form'),
			marketForm = $('.market-form'),
			consultForm = $('.consult-form'),
			questionsForm = $('.main-form'),
			requestForm = $('.request-form'),
			descriptionForm = $('.form_desc');

		if(document.location.hash != "") {
			var hash = document.location.hash;
			$('html, body').scrollTo($(hash).offset()['top']-50,500);
		}
		// Перезвоните мне //
		$('form').submit(function(){
			try {
				yaCounter37563845.reachGoal("submit");
			} catch(e) {}
		});

		$('.info-block').on('click', '.call-open', function() {
			callForm.find('form').submit(function() {
				try {
				yaCounter37563845.reachGoal("CALL_ME");
				} catch(e) {}
			});
		});

		$('#screen-one').on('click', '.consult-open', function() {
			consultForm.find('form').submit(function() {
				try {
				yaCounter37563845.reachGoal("GET_CONSULTATION");
				} catch(e) {}
			});
		});

		$('#screen-three').on('click', '.consult-open', function() {
			consultForm.find('form').submit(function() {
				try {
				yaCounter37563845.reachGoal("VIDEO_CONSULTATION");
				} catch(e) {}
			});
		});

		$('#screen-plan').on('click', '.market-open', function() {
			marketForm.find('form').submit(function() {
				try {
				yaCounter37563845.reachGoal("TURBO");
				} catch(e) {}
			});
		});

		$('#screen-work').on('click', '.consult-open', function() {
			consultForm.find('form').submit(function() {
				try {
				yaCounter37563845.reachGoal("WORK_CONSULTATION");
				} catch(e) {}
			});
		});

		$('#screen-two').on('click', '.consult-open', function() {
			consultForm.find('form').submit(function() {
				try {
				yaCounter37563845.reachGoal("REASONS_CONSULTATION");
				} catch(e) {}
			});
		});

		$('#screen-five').on('click', '.request-open', function() {
			requestForm.find('form').submit(function() {
				try {
				yaCounter37563845.reachGoal("LEAVE_REQUEST");
				} catch(e) {}
			});
		});

		$('#screen-guarantee').on('click', '.request-open', function() {
			requestForm.find('form').submit(function() {
				try {
				yaCounter37563845.reachGoal("GUARANTEE_REQUEST");
				} catch(e) {}
			});
		});

		questionsForm.submit(function() {
			try {
			yaCounter37563845.reachGoal("QUESTIONS");
			} catch(e) {}
		});

		descriptionForm.submit(function() {
			try {
			yaCounter37563845.reachGoal("LOOK");
			} catch(e) {}
		});

	});
})();