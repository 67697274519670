ymaps.ready(init);
var myMap;

function init(){     
    myMap = new ymaps.Map("map", {
        center: [55.4212,37.5356],
        zoom: 15,
        controls: ["zoomControl"],
        scrollwheel: false
    });

    myPlacemark = new ymaps.Placemark([55.4234,37.5298], {
    	hintContent: 'Leadman Brokers',
    	balloonContent: 'Агентство недвижимости Leadman Brokers'
    });

    myMap.geoObjects.add(myPlacemark);
    myMap.behaviors.disable('scrollZoom');
}