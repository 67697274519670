(function() {

	//swiperWrapper.addClass('swiper-wrapper');

	var swiper = new Swiper('.reviews__container', {
		wrapperClass: 'reviews__wrapper',
		slideClass: 'reviews__slide',
		slidesPerView: 'auto',
		centeredSlides: true,
		nextButton: '.reviews__next',
		prevButton: '.reviews__prev',
		//paginationClickable: true,
		spaceBetween: 100,
		initialSlide: 1,
		// autoplayDisableOnInteraction: false,
		autoplay: 4000,
		//buttonDisabledClass: 'event-slider-dsbld'
		// freeMode: false,
		// loopedSlides: $('.event-slider-slide').length,
		//loop: true
	});

	var examplesSwiper = new Swiper('.examples__container', {
		wrapperClass: 'examples__wrapper',
		slideClass: 'examples__slide',
		slidesPerView: 'auto',
		centeredSlides: true,
		nextButton: '.examples__next',
		prevButton: '.examples__prev',
		initialSlide: 1,
		//paginationClickable: true,
		spaceBetween: 100
		// autoplayDisableOnInteraction: false,
		//autoplay: 4000,
		//buttonDisabledClass: 'event-slider-dsbld'
		// freeMode: false,
		// loopedSlides: $('.event-slider-slide').length,
		//loop: true
	});

})();