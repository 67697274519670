(function() {
	if(!jQuery.browser.mobile){
		$('.form__input[name="name"]').inputmask({"mask": "a", "repeat" : 15, "greedy": false});
		$('.form__input[name="phone"]').inputmask({"mask": "+7 (999) 999-9999"});
		$('.form__input[name="email"]').inputmask({ alias: "email"});
	}
	var popUps = $('.popup'),
		popUpOpen = $('.popup-open'),
		popUpClose = $('.popup__close'),
		descOpen = $('.description-open'),
		descClose = $('.popup_obj__close'),
		marketOpen = $('.market-open');

	var callForm = $('.call-form'),
		runForm = $('.run-form'),
		requestForm = $('.request-form'),
		consultForm = $('.consult-form'),
		objForm = $('.popup_obj'),
		marketForm = $('.market-form');

	popUpOpen.each(function() {
		$(this).click(function(event) {
			event.preventDefault();
			try {
				yaCounter37563845.reachGoal("open");
			} catch(e) {}

			$('html').css('overflow', 'hidden');

			if ($(this).is(".market-open")) {
				marketForm.show();
				marketForm.find('input[name=form1]').attr('value',$(this).attr('data-pos'));
			}
			if ($(this).is(".call-open")) {
				callForm.show();
				callForm.find('input[name=form1]').attr('value',$(this).attr('data-pos'));
			}
			if ($(this).is(".consult-open")) {
				consultForm.show();
				consultForm.find('input[name=form1]').attr('value',$(this).attr('data-pos'));
			}
			if ($(this).is(".run-open")) {
				runForm.show();
				runForm.find('input[name=form1]').attr('value',$(this).attr('data-pos'));
			}
			if ($(this).is(".request-open")) {
				requestForm.show();
				requestForm.find('input[name=form1]').attr('value',$(this).attr('data-pos'));
			}
		});
	});



	descOpen.each(function() {
		var auction_desc = $('.popup_obj__desc'),
			auction_price = $('.popup_obj__price'),
			auction_date = $('.popup_obj__date'),
			form_info = objForm.find('input[name=form1]');


		$(this).click(function(event) {

			try {
				yaCounter37563845.reachGoal("open");
			} catch(e) {}
			/*
			var clickedId = $(this).data('id');
			var photo_paths = [];
			$('.popup_obj__wrapper').html('<!-- Slides -->');

			// Наполняем объект фотографиями именно этой квартиры //

			$.each(photos, function(n) {
				if ($(this)[0].auctions_model_id == clickedId) {

					photo_paths[n] = $(this)[0].photo_url;
				}
			});

			// Создаем слайды к каждой фотографии в объекте фоток //

			$.each(photo_paths, function(key, value) {
				if (value != undefined) {
					$('.popup_obj__wrapper').append('<div class="swiper-slide popup_obj__slide"><div class="popup_obj__img-overlay"><img src=' + value + '></div></div>');
				}
			});

			// Добавляем информацию об объекте //

			$.each(auctions, function() {
				if ($(this)[0].id == clickedId) {

					// Add info

					auction_desc.html($(this)[0].auction_description);
					auction_price.html('Цена: ' + $(this)[0].auction_price + ' ₽');
					auction_date.html('Квартира будет продана до ' + $(this)[0].auction_date_end);
					form_info.attr('value','ID: ' + clickedId +', Цена: ' + $(this)[0].auction_price + '₽, Дата: с ' + $(this)[0].auction_date_start + ' по ' + $(this)[0].auction_date_end);

				}
			});

			/////////////////////////////
			*/

			event.preventDefault();
			$('html').css('overflow', 'hidden');
			$('#auction-'+$(this).data('id')+' .popup_obj__wrapper').html(sliderHTML[$(this).data('id')]);
			$('#auction-'+$(this).data('id')).show();

			$('#auction-'+$(this).data('id')).find('.popup_obj__wrap').addClass('animated zoomIn');
			if(objectSwiper[parseInt($(this).data('id'))] == 'undefined') {
				objectSwiper[$(this).data('id')].update(true);
			} else {
				objectSwiper[$(this).data('id')] = new Swiper('#auction-'+$(this).data('id')+' .popup_obj__container', {
					wrapperClass: 'popup_obj__wrapper',
					slideClass: 'popup_obj__slide',
					slidesPerView: 'auto',
					centeredSlides: true,
					autoplay: 4000,
					nextButton: '.popup_obj__next',
					prevButton: '.popup_obj__prev',
					initialSlide: 2,
					//paginationClickable: true,
					spaceBetween: 40,
					// autoplayDisableOnInteraction: false,
					//autoplay: 4000,
					//buttonDisabledClass: 'event-slider-dsbld'
					// freeMode: false,
					// loopedSlides: $('.event-slider-slide').length,
					//loop: true
					});
			}
		});
	});

	// Закрытие //

	popUpClose.each(function() {
		$(this).click(function(event) {
			event.preventDefault();
			$('html').css('overflow', 'auto');
			popUps.hide();
		})
	});

	descClose.each(function() {
		$(this).click(function(event) {
			event.preventDefault();
			$('html').css('overflow', 'auto');
			objForm.hide();
		})
	});

	// Закрытие по неактивной области //

	popUps.click(function(event){
    	if($(event.target).closest('.popup form').length) {
    		return;
    	}

    	$('html').css('overflow', 'auto');
   		$(this).hide();
    	event.stopPropagation();
    });

    objForm.click(function(event){
    	if($(event.target).closest('.popup_obj__wrap').length) {
    		return;
    	}

    	$('html').css('overflow', 'auto');
   		$(this).hide();
    	event.stopPropagation();
    });
})();

(function() {

	var submitBtns = $('.form__submit'),
		descSubmitBtns = $('.form_desc__submit');

	function validate(inputs, errorMessage) {
		var errors = 0;

		inputs.each(function() {
			if ($(this).val() == '') {
				errors = (errors + 1);
			}
		});

		if (errors != 0) {
			event.preventDefault();
			errorMessage.show();
			errors = 0;
			return false;
		}
	}

	submitBtns.each(function() {
		var inputs = $(this).parent().children('.form__input'),
				errorMessage =  $(this).parent().children('.error-message');

		if($(this).is('.marketplan')){
			$(this).parent('form').submit(function(e){
				e.preventDefault();
				errorMessage.show().html('Пожалуйста подождите');
				/*try {
					yaCounter39744005.reachGoal($(this).data('goal'));
				} catch {}*/
				submitBtns.attr('disabled','');
				window.open('/files/marketplan.pdf');
				var f = $(this);


				var d = f.serialize();

				var a = f.attr('action');

				$.ajax({
	        url: a,
	        type: "post",
	        data: d,
	        success: function(data){
						errorMessage.show().html('Заявка успешно отправлена');
					}
				});
			})
		}
	});

	var descInputs = $('.form_desc__input'),
		descErrorMessage =  $('.form_desc .error-message');

})();
