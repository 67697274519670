/*(function() {
	var titleTimer = $('.title_date'),
		dateInput = $('.form__date'),
		today = new Date();

	var year = today.getFullYear(),
		month = today.getMonth(),
		day = today.getDate(),
		date;

	if (month < 10) {
		month = '0' + month;
	}

	if (day < 10) {
		day = '0' + day;
	}

	date = day + '.' + month + '.' + year;

	titleTimer.html('Аукционы на ' + date);

	dateInput.each(function() {
		$(this).datepicker({
			todayHighlight: true,
			language: "ru"
		});
	});

})();*/